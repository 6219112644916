import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
export const BlogPostQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(relativePath: { eq: "post/demo-post/images/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  BlogPostQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`This is my blog`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      